<template>
    <div id="createDataSet">
        <div class="create-dataSet">
            <!-- heading of data-set and input field for data-set name -->
            <mds-layout-grid class="create-dataSet">
                <mds-row>
                    <span>
                        <router-link
                            :to="{
                                name: getRouteDetails('/settings/data-sets'),
                            }"
                        >
                            Data Sets
                        </router-link>
                    </span>
                    <span class="create-dataSet-router">
                        > Create Data Set</span
                    >
                </mds-row>
                <mds-row class="create-dataSet-heading">
                    Create Data Set
                </mds-row>

                <mds-row class="create-dataSet-selection-section">
                    <!-- Create Universe Input -->
                    <mds-col :cols="4" class="create-dataSet-inputfield">
                        <mds-form>
                            <mds-input
                                label="Data Set Name"
                                maxlength="250"
                                placeholder="Enter a data set name"
                                v-model.trim="dataSetname"
                                :disabled="!isEditModeOn"
                            ></mds-input>
                        </mds-form>
                    </mds-col>
                </mds-row>

                <mds-row class="create-dataSet-border"></mds-row>
                <mds-row class="data-set-heading">Data Point List</mds-row>

                <!-- HTML for Data Point List -->
                <mds-row class="data-point-section">
                    <!-- middle-section 1st column -->
                    <mds-col :cols="2" class="data-point-radio-button">
                        <!-- redio button for entity-level -->
                        <mds-form class="data-point-entity-level">
                            <mds-fieldset>
                                <mds-radio-button
                                    name="group1"
                                    v-for="item in getEntityTypes"
                                    :key="item.entitytypeid"
                                    v-model="entityType"
                                    :value="item.entitytypeid"
                                    :label="item.entitytypedescription"
                                    @input="switchEntityLevel(item)"
                                ></mds-radio-button>
                            </mds-fieldset>
                        </mds-form>

                        <!-- radio button for data type -->
                        <mds-form>
                            <mds-fieldset>
                                <mds-radio-button
                                    name="group1"
                                    v-for="item in getDataType"
                                    v-model="switchDataType"
                                    :key="item.id"
                                    :value="item.id"
                                    :label="item.text"
                                    @input="changeDataType(item)"
                                ></mds-radio-button>
                            </mds-fieldset>
                        </mds-form>
                    </mds-col>
                    <span class="data-set-vertical-line"></span>

                    <!-- data Point list with resp to entityid or data group -->
                    <!-- middle seaction 2nd column -->
                    <mds-col :cols="4" class="data-point-list-section">
                        <mds-search-field
                            placeholder="Search..."
                            label="Search"
                            v-model="dataPointInput"
                            @mds-search-field-input-cleared="clearSearchInput()"
                        ></mds-search-field>

                        <!-- List of Criteria -->
                        <mds-list-group
                            class="data-point-list-group"
                            variation="selection"
                            v-if="isDataPointSelected"
                            :key="dataPointKey"
                        >
                            <!-- list for data point -->
                            <mds-list-group-item
                                class="dynamic-list-group-item"
                                v-for="(
                                    addCriterias, index
                                ) in getDataPointSelectionList"
                                :key="index"
                            >
                                <span
                                    @click="selectionOfDataPoint(addCriterias)"
                                    >{{ addCriterias.name }}</span
                                >
                            </mds-list-group-item>

                            
                        <mds-empty-state
                            v-if="getDataPointSelectionList.length == 0"
                            class="no-results-message"
                            size="medium"
                            title="No data found"
                            message=""
                        ></mds-empty-state>
                        </mds-list-group>

                        <!-- list for data Group -->
                        <mds-list-group
                            class="data-point-list-group"
                            variation="selection"
                            v-if="isDataGroupSelected"
                            :key="dataGroupKey"
                        >
                            <mds-list-group-item
                                class="dynamic-list-group-item"
                                v-for="(
                                    addCriterias, i
                                ) in getonlyDataGroupList"
                                :key="i"
                            >
                                <span
                                    @click="selectionOfDataPoint(addCriterias)"
                                    >{{ addCriterias.name }}</span
                                >
                            </mds-list-group-item>
                            <mds-empty-state
                            v-if="getonlyDataGroupList.length == 0"
                            class="no-results-message"
                            size="medium"
                            title="No data found"
                            message=""
                        ></mds-empty-state>
                        </mds-list-group>

                        <span></span>
                    </mds-col>

                    <span class="data-set-vertical-line"></span>

                    <!-- middle section 3rd column -->
                    <mds-col class="data-point-section-information">
                        <mds-row class="data-point-heading">{{
                            dataPointName
                        }}</mds-row>
                        <mds-row class="data-point-discription">{{
                            discription
                        }}</mds-row>
                        <mds-row
                            v-if="isDataTypeSelect"
                            style="
                                border-top: solid 1px #cccccc;
                                margin-bottom: 8px;
                            "
                        ></mds-row>

                        <mds-row class="data-set-selection-of-value-language">
                            <span
                                v-if="isDataTypeSelect && isDataGroupSelected"
                                class="data-point-heading"
                                >Data Point Included :</span
                            >
                            <span
                                class="
                                    selected-datapoint-language-using-checkbox
                                "
                            >
                                <mds-form
                                    v-if="
                                        isDataTypeSelect && isDataPointSelected
                                    "
                                >
                                    <mds-fieldset>
                                        <mds-checkbox
                                            name="group1"
                                            v-for="(
                                                language, index
                                            ) in multiLanguage"
                                            :checked="language.isSelected"
                                            :key="index"
                                            :value="language.text"
                                            :label="language.text"
                                            @change="
                                                selectLanguage($event, language)
                                            "
                                        ></mds-checkbox>
                                    </mds-fieldset>
                                </mds-form>

                                <mds-list-group
                                    v-if="
                                        isDataTypeSelect && isDataGroupSelected
                                    "
                                >
                                    <mds-list-group-item
                                        v-for="(
                                            datapointarray, index
                                        ) in dataPointArrayGroup"
                                        :key="index"
                                    >
                                        <span>{{ datapointarray.name }}</span>
                                    </mds-list-group-item>
                                </mds-list-group>
                            </span>
                        </mds-row>

                        <mds-row
                            style="justify-content: flex-end; display: flex"
                        >
                            <button-component
                                class="addToList-button"
                                :onClick="saveSelectedDataType"
                                buttonName="Add to List"
                                iconName="plus"
                                buttonVariation="primary"
                                :isDisabled="isListEmpty"
                            >
                            </button-component>
                        </mds-row>
                    </mds-col>
                </mds-row>

                <mds-row class="create-dataSet-border"></mds-row>
                <mds-row
                    class="data-set-selected-data-type-heading"
                    v-if="isSelectedTypeSection"
                    >Your Selection:
                    <span
                        v-if="isSelectedTypeSection"
                        class="data-set-selction-heading-margin"
                        >{{ selectedDataPointCount }} Data Points</span
                    >
                    <span v-if="isSelectedTypeSection">
                        , {{ selectedDataGroupCount }} Data Groups</span
                    >
                </mds-row>

                <!-- HTML for selected Data Point and Data Point Group -->
                <mds-row class="data-set-selected-data-type-list">
                    <!-- for data-point -->
                    <mds-row
                        class="data-set-selected-data-type"
                        v-if="isSelectedTypeSection"
                    >
                        <template v-if="showSelectedDataPoint.length > 0">
                            <span>Data Points</span>
                            <mds-list-group
                                class="data-set-selected-data-type-name"
                                v-for="(
                                    showselecteddatapoint, val
                                ) in showSelectedDataPoint"
                                :key="val"
                            >
                                <mds-row
                                    class="data-Set-list-item-border"
                                ></mds-row>
                                <!-- list group with data point name only -->
                                <mds-list-group-item
                                    v-if="
                                        showselecteddatapoint.langcode && showselecteddatapoint.langcode.length ==
                                        0
                                    "
                                    class="dynamic-selected-list"
                                >
                                    <span>{{
                                        showselecteddatapoint.name
                                    }}</span>
                                    <template v-slot:mds-list-item-right>
                                        <mds-button
                                            class="
                                                dynamic-remove-selected-value
                                            "
                                            icon="trash"
                                            variation="icon-only"
                                            size="small"
                                            type="button"
                                            @click="
                                                removeSingleDataTypeName(
                                                    showselecteddatapoint
                                                )
                                            "
                                        ></mds-button>
                                    </template>
                                </mds-list-group-item>

                                <!-- list-group with data point and language -->
                                <mds-list-group-item
                                    class="dynamic-selected-list"
                                    v-for="(
                                        value, valIndex
                                    ) in showselecteddatapoint.langcode"
                                    :key="valIndex"
                                >
                                    <span>{{
                                        showselecteddatapoint.name
                                    }}</span>
                                    <span> ( {{ value.text }} )</span>

                                    <!-- Remove single value from selected list -->
                                    <template v-slot:mds-list-item-right>
                                        <mds-button
                                            class="
                                                dynamic-remove-selected-value
                                            "
                                            icon="trash"
                                            variation="icon-only"
                                            size="small"
                                            type="button"
                                            @click="
                                                removeSingleDataTypeNameWithLanguage(
                                                    showselecteddatapoint.name,
                                                    value
                                                )
                                            "
                                        ></mds-button>
                                    </template>
                                </mds-list-group-item>
                            </mds-list-group>
                        </template>
                    </mds-row>

                    <!-- for data group -->
                    <mds-row
                        class="data-set-selected-data-type"
                        v-if="isSelectedTypeSection"
                    >
                        <template v-if="showSelectedDataGroup.length > 0">
                            <span>Data Groups</span>
                            <mds-list-group
                                class="data-set-selected-data-type-name"
                                v-for="(
                                    showselecteddatagroup, res
                                ) in showSelectedDataGroup"
                                :key="res"
                            >
                                <mds-row
                                    class="data-Set-list-item-border"
                                ></mds-row>
                                <!-- list group with data group only -->
                                <mds-list-group-item
                                    class="dynamic-selected-list"
                                >
                                    <span>{{
                                        showselecteddatagroup.name
                                    }}</span>
                                    <template v-slot:mds-list-item-right>
                                        <mds-button
                                            class="
                                                dynamic-remove-selected-value
                                            "
                                            icon="trash"
                                            variation="icon-only"
                                            size="small"
                                            type="button"
                                            @click="
                                                removeSingleDataGroupName(
                                                    showselecteddatagroup
                                                )
                                            "
                                        ></mds-button>
                                    </template>
                                </mds-list-group-item>
                            </mds-list-group>
                        </template>
                    </mds-row>
                </mds-row>

                <!-- Html for save and cancel button -->
                <mds-row class="data-set-footer-section">
                    <mds-col>
                        <button-component
                            :onClick="cancelButton"
                            buttonName="Cancel"
                            buttonVariation="secondary"
                        >
                        </button-component>
                    </mds-col>
                    <mds-col style="text-align: right">
                        <button-component
                            class="bottom-section-save-button"
                            :onClick="saveButton"
                            buttonName="Save"
                            buttonVariation="primary"
                            :isDisabled="!saveButtonDisable"
                        >
                        </button-component>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>
        </div>
        <loader-component v-if="showLoader"></loader-component>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
        ></notification-component>
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsForm from "@mds/form";
import MdsInput from "@mds/input";
import ButtonComponent from "../ui_component/ButtonComponent.vue";
import MdsFieldset from "@mds/fieldset";
import MdsRadioButton from "@mds/radio-button";
import { mapActions, mapGetters } from "vuex";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import MdsSearchField from "@mds/search-field";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import MdsCheckbox from "@mds/checkbox";
import { MdsButton } from "@mds/button";
import NotificationComponent from "../ui_component/notificationComponent.vue";
import { getEditDataSet } from "../../services/reporting_service.js";
import MdsEmptyState from "@mds/empty-state";

export default {
    name: "reportingCreateDataSet",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsForm,
        MdsInput,
        ButtonComponent,
        LoaderComponent,
        MdsFieldset,
        MdsRadioButton,
        MdsSearchField,
        MdsListGroup,
        MdsListGroupItem,
        MdsCheckbox,
        MdsButton,
        NotificationComponent,
        MdsEmptyState,
    },
    data() {
        return {
            selected: true,
            dataSetId: 0,
            dataSetname: "",
            isEditModeOn: true,
            showLoader: true,
            entityTypeId: 1,
            dataPointInput: "",
            getDataPointSelectionList: [],
            getonlyDataGroupList: [],
            isListEmpty: true,
            discription: "",
            dataPointName: "",
            isDataTypeSelect: false,
            isDataGroupSelected: false,
            isDataPointSelected: false,
            tempDataPointArray: [],
            tempDataGroupArray: [],
            selectedLanguage: [],
            isSelectedTypeSection: false,
            dataPointId: 0,
            showSelectedDataPoint: [],
            selectedDataPointCount: 0,
            selectedDataGroupCount: 0,
            DataPointRequest: [],
            DataGroupRequest: [],
            showNotification: false,
            switchDataType: "",
            entityType: "",
            editDataSet: [],
            editgroupListValue: [],
            editMode: false,
            dataPointArrayGroup: [],
            dataPointArray: [],
            editDataPoint: [],
            editDataGroup: [],
            getDataType: [
                {
                    id: 1,
                    text: "Data Point",
                },
                {
                    id: 2,
                    text: "Data Group",
                },
            ],

            multiLanguage: [],
            selectedDataPoint: [],
            selectedDataGroup: [],
            showSelectedDataGroup: [],
            showSelectedDataType: [],
            isDataPointMultiLanguageUpdated: false,
            dataGroupKey: 0,
            dataPointKey: 0,
        };
    },

    async mounted() {
        this.showLoader = true;
        const dataSetId = this.$route.query.dataSetId;
        if (dataSetId) {
            this.editMode = true;
            this.isEditModeOn = false;
            await this.fetchDataSetDataPonitList(dataSetId);
            this.editDataSet = this.getDataSetDataPointList.data;
            if(this.getDataSetDataPointList.isError == false){
                this.isSelectedTypeSection = true;
                this.dataSetname = this.editDataSet.datasetname;
                this.dataSetId = this.editDataSet.datasetid;
                this.showSelectedDataGroup = this.editDataSet.groupdatapoints;
                this.showSelectedDataPoint = this.editDataSet.singledatapoints;
                this.selectedDataPointCount = this.editDataSet.singledatapointcount;
                this.selectedDataGroupCount = this.editDataSet.groupdatapointcount;
            }else{
                this.notificationType = "error";
                this.notificationMessage = this.editDataSet.message;
                this.showNotification = true;
                this.showLoader = false;
                setTimeout(() => {
                    this.showNotification = false;
                    this.$router.push({ name: "Reporting Data Sets" });
                }, 2000)
            }
        } else {
            this.editMode = false;
        }
        this.datasetId = 0;
        this.switchDataType = 1;
        this.entityType = 1;
        await this.fetchEntityTypes();
        await this.fetchDynamicDataPoint(this.entityTypeId);

        await this.fetchLanguageDetails();
        this.getLanguages.forEach((item) => {
            item.isSelected = false;
            this.multiLanguage.push(item);
        });
        this.getDataPointSelectionList = [];
        this.isDataPointSelected = true;
        this.getDynamicDataPoint.forEach((element) => {
            if (element.datapointgroups === null) {
                this.getDataPointSelectionList.push(element);
            }
        });
        this.tempDataPointArray = this.getDataPointSelectionList;

        // if (this.showSelectedDataPoint.length > 0) {
        //   this.showSelectedDataPoint.forEach((item) => {
        //     if (item.langcode.length == 0) {
        //       this.getDataPointSelectionList = this.getDataPointSelectionList.filter(
        //         (result) => result.id !== item.id
        //       );
        //     }
        //   });
        // }
        this.showLoader = false;
    },

    watch: {
        dataPointInput() {
            this.searchInput(this.dataPointInput);
        },
    },

    computed: {
        ...mapGetters("entity", ["getEntityTypes"]),
        ...mapGetters("reporting", [
            "getDynamicDataPoint",
            "getLanguages",
            "getSaveDataSet",
            "getDataSetDataPointList",
        ]),

        saveButtonDisable() {
            if (
                this.showSelectedDataGroup.length === 0 &&
                this.showSelectedDataPoint.length === 0
            ) {
                return;
            } else {
                return this.showSelectedDataType, this.dataSetname;
            }
        },
    },

    methods: {
        ...mapActions("entity", [
            "fetchEntityTypes",
            "fetchDropdownListEntity",
        ]),
        ...mapActions("reporting", [
            "fetchDynamicDataPoint",
            "fetchLanguageDetails",
            "fetchSaveDataSet",
            "fetchDataSetDataPonitList",
        ]),

        // redirect to data-set home page
        cancelButton() {
            this.$router.push({ name: "Reporting Data Sets" });
        },

        // save or edit data-set and redirect to data-set home page
        async saveButton() {
            // for edit data-set
            if (this.editDataSet.length !== 0) {
                let EditDataSetRequest = {
                    dataSetId: 0,
                    dataSetName: "",
                    singleDataPoints: [],
                    groupDataPoints: [],
                    singleDataPointCount: 0,
                    groupDataPointCount: 0,
                };
                EditDataSetRequest.dataSetId = this.dataSetId;
                EditDataSetRequest.dataSetName = this.dataSetname;
                EditDataSetRequest.singleDataPoints =
                    this.showSelectedDataPoint;
                EditDataSetRequest.groupDataPoints = this.showSelectedDataGroup;
                EditDataSetRequest.singleDataPointCount =
                    this.selectedDataPointCount;
                EditDataSetRequest.groupDataPointCount =
                    this.selectedDataGroupCount;

                let response = await getEditDataSet(EditDataSetRequest);
                if (response.data.statuscode != 200) {
                    this.notificationType = "error";
                    this.notificationMessage = response.data.message;
                    this.showNotification = true;
                    this.showLoader = false;
                    setTimeout(() => {
                        this.showNotification = false;
                    }, 2000);
                } else {
                    this.notificationType = "success";
                    this.notificationMessage = "Data set successfully updated";
                    this.showNotification = true;
                    setTimeout(() => {
                        this.$router.push({ name: "Reporting Data Sets" });
                        this.showNotification = false;
                        this.showLoader = false;
                    }, 2000);
                }
            }
            // for create data-set
            else {
                let DataSetRequest = {
                    dataSetName: "",
                    singleDataPoints: [],
                    groupDataPoints: [],
                    singleDataPointCount: 0,
                    groupDataPointCount: 0,
                };

                DataSetRequest.dataSetName = this.dataSetname;
                DataSetRequest.singleDataPoints = this.showSelectedDataPoint;
                DataSetRequest.groupDataPoints = this.showSelectedDataGroup;
                DataSetRequest.singleDataPointCount =
                    this.selectedDataPointCount;
                DataSetRequest.groupDataPointCount =
                    this.selectedDataGroupCount;
                await this.fetchSaveDataSet(DataSetRequest);
                let response = this.getSaveDataSet.data;
                if (response.statuscode != 201) {
                    this.notificationType = "error";
                    this.notificationMessage = response.message;
                    this.showNotification = true;
                    this.showLoader = false;
                    setTimeout(() => {
                        this.showNotification = false;
                    }, 2000);
                } else {
                    this.notificationType = "success";
                    this.notificationMessage = "Data set successfully created";
                    this.showNotification = true;
                    setTimeout(() => {
                        this.$router.push({ name: "Reporting Data Sets" });
                        this.showNotification = false;
                        this.showLoader = false;
                    }, 2000);
                }
            }
        },

        // event to show data point list and data group list
        async switchEntityLevel(array) {
            this.entityTypeId = array.entitytypeid;
            this.showLoader = true;
            this.isDataTypeSelect = false;
            this.dataPointName = "";
            this.discription = "";
            this.dataPointInput = "";
            this.isListEmpty = true;
            await this.fetchDynamicDataPoint(this.entityTypeId);
            this.getDataPointSelectionList = [];
            this.getonlyDataGroupList = [];
            this.tempDataPointArray = [];
            this.tempDataGroupArray = [];
            this.multiLanguage = this.getLanguages;

            this.getDynamicDataPoint.forEach((element) => {
                if (element.datapointgroups === null) {
                    this.getDataPointSelectionList.push(element);
                } else {
                    this.entityTypeId = array.entitytypeid;
                    this.getonlyDataGroupList.push(element);
                }
            });
            // if (this.showSelectedDataGroup.length > 0) {
            //   this.showSelectedDataGroup.forEach((item) => {
            //     this.getonlyDataGroupList = this.getonlyDataGroupList.filter(
            //       (result) => result.id !== item.id
            //     );
            //   });
            // }
            // if (this.showSelectedDataPoint.length > 0) {
            //   this.showSelectedDataPoint.forEach((item) => {
            //     if (item.langcode.length == 0) {
            //       this.getDataPointSelectionList = this.getDataPointSelectionList.filter(
            //         (result) => result.id !== item.id
            //       );
            //     }
            //   });
            // }
            this.tempDataPointArray = this.getDataPointSelectionList;
            this.tempDataGroupArray = this.getonlyDataGroupList;
            this.showLoader = false;
        },

        // Route to Reporting Data Set page
        getRouteDetails(url) {
            const routeDetails = this.$router.resolve({
                path: url,
            });
            return routeDetails.resolved.name;
        },

        // search input field from data-point as well as data-group
        searchInput(val) {
            if (this.isDataPointSelected === true) {
                if (this.dataPointInput == "") {
                    this.getDataPointSelectionList = this.tempDataPointArray;
                }
                this.searchDataPoint(val);
            } else {
                if (this.dataPointInput == "") {
                    this.getonlyDataGroupList = this.tempDataGroupArray;
                }
                this.searchDataGroup(val);
            }
        },

        searchDataPoint(searched) {
            this.getDataPointSelectionList = this.tempDataPointArray.filter(
                (c) => {
                    return (
                        c.name.toUpperCase().indexOf(searched.toUpperCase()) >
                        -1
                    );
                }
            );
        },

        searchDataGroup(searched) {
            this.getonlyDataGroupList = this.tempDataGroupArray.filter((c) => {
                return (
                    c.name.toUpperCase().indexOf(searched.toUpperCase()) > -1
                );
            });
        },

        // clear input field and retain all data point and data group list
        clearSearchInput() {
            if (this.isDataPointSelected === true) {
                this.getDataPointSelectionList = this.tempDataPointArray;
            } else {
                this.getonlyDataGroupList = this.tempDataGroupArray;
            }
        },

        // clicking on each selected data-point or data-group
        async selectionOfDataPoint(array) {
            this.dataPointId = array.id;
            this.dataPointName = array.name;
            this.discription = array.description;
            this.isDataTypeSelect = false;
            this.isListEmpty = true;
            this.dataPointInput = "";
            this.getDataPointSelectionList = this.tempDataPointArray;

            if (this.isDataPointSelected === true) {
                this.dataPointKey += 1;
                if (array.ismultilang == true) {
                    this.isDataTypeSelect = true;
                    if (this.showSelectedDataPoint.length > 0) {
                        let result = [];
                        result = this.showSelectedDataPoint.filter(
                            (item) => item.name == array.name
                        );
                        if (result.length > 0) {
                            this.multiLanguage = this.getLanguages;
                            this.multiLanguage.forEach((res) => {
                                res.isSelected = false;
                            });
                            result.forEach((item) => {
                                item.langcode && item.langcode.forEach((result) => {
                                    this.multiLanguage.forEach((resultItem) => {
                                        if (result.text == resultItem.text) {
                                            resultItem.isSelected = true;
                                        }
                                    });
                                });
                            });
                        } else if (result.length == 0) {
                            this.multiLanguage = this.getLanguages;
                            this.multiLanguage.forEach((element) => {
                                element.isSelected = false;
                            });
                        }
                    } else if (this.showSelectedDataPoint.length == 0) {
                        this.multiLanguage.forEach((item) => {
                            item.isSelected = false;
                        });
                    }
                } else if (array.ismultilang == false) {
                    this.isListEmpty = false;
                }
            } else if (this.isDataGroupSelected === true) {
                this.dataGroupKey += 1;
                this.isDataTypeSelect = true;
                this.dataPointArrayGroup = array.datapointgroups;
                this.isListEmpty = false;
            }
        },

        // change data type from data group to data point and vice versa
        changeDataType(array) {
            this.isDataTypeSelect = false;
            this.dataPointName = "";
            this.discription = "";
            this.dataPointInput = "";
            this.isListEmpty = true;
            if (array.id == 1) {
                this.isDataPointSelected = true;
                this.isDataGroupSelected = false;
                this.getDataPointSelectionList = this.tempDataPointArray;

                // if (this.showSelectedDataPoint.length > 0) {
                //   this.showSelectedDataPoint.forEach((item) => {
                //     if (item.langcode.length == 0) {
                //       this.getDataPointSelectionList = this.getDataPointSelectionList.filter(
                //         (result) => result.id !== item.id
                //       );
                //     }
                //   });
                // }
            } else {
                this.isDataPointSelected = false;
                this.isDataGroupSelected = true;
                this.getonlyDataGroupList = this.tempDataGroupArray;
                // if (this.showSelectedDataGroup.length > 0) {
                //   this.showSelectedDataGroup.forEach((item) => {
                //     this.getonlyDataGroupList = this.getonlyDataGroupList.filter(
                //       (result) => result.id !== item.id
                //     );
                //   });
                // }
            }
        },

        // after clicking any language store it into array
        selectLanguage(event, array) {
            this.isListEmpty = false;
            if (event == true) {
                const obj = {
                    value: array.value,
                    text: array.text,
                };
                this.selectedLanguage.push(obj);
            } else if (event == false) {
                this.removeSingleDataTypeNameWithLanguage(
                    this.dataPointName,
                    array.text
                );

                this.selectedLanguage = this.selectedLanguage.filter(
                    (item) => item !== array.text
                );
                if (this.selectedLanguage.length == 0) {
                    this.isListEmpty = true;
                }
            }
        },

        // click event on add to list button
        saveSelectedDataType() {
            this.isSelectedTypeSection = true;
            this.isListEmpty = true;
            this.discription = "";

            if (this.isDataPointSelected == true) {
                this.showSelectedDataPoint.forEach((item, index) => {
                    if (
                        item.id == this.dataPointId &&
                        this.selectedLanguage.length == 0
                    ) {
                        this.showSelectedDataPoint.splice(index, 1);
                        this.selectedDataPointCount -= 1;
                    }
                });
                let object = {};
                if (this.selectedLanguage.length > 1) {
                    this.selectedLanguage.map((item) => {
                        object = {
                            id: this.dataPointId,
                            EntityTypeId: this.entityTypeId,
                            name: this.dataPointName,
                            langcode: [item],
                        };
                        this.createSelectedDataPoint(object);
                    });
                } else {
                    object = {
                        id: this.dataPointId,
                        EntityTypeId: this.entityTypeId,
                        name: this.dataPointName,
                        langcode: this.selectedLanguage,
                    };
                    this.createSelectedDataPoint(object);
                }

                this.tempDataPointArray = this.getDataPointSelectionList;
            } else if (this.isDataGroupSelected == true) {
                this.showSelectedDataGroup.forEach((item, index) => {
                    if (item.id == this.dataPointId) {
                        this.showSelectedDataGroup.splice(index, 1);
                        this.selectedDataGroupCount -= 1;
                    }
                });
                let object = {
                    id: 0,
                    EntityTypeId: 0,
                    name: "",
                    dataPoints: [],
                };

                object.id = this.dataPointId;
                object.name = this.dataPointName;
                object.EntityTypeId = this.entityTypeId;
                this.createDatapoints();
                object.dataPoints = this.dataPointArray;

                this.createSelectedDataGroup(object);
                this.tempDataGroupArray = this.getonlyDataGroupList;
            }

            if (
                this.selectedLanguage.length === 0 &&
                this.isDataPointSelected
            ) {
                this.selectedDataPointCount += 1;
            } else if (
                this.selectedLanguage.length !== 0 &&
                this.isDataPointSelected
            ) {
                this.selectedDataPointCount += this.selectedLanguage.length;
            } else if (
                this.selectedLanguage.length === 0 &&
                this.isDataGroupSelected
            ) {
                this.selectedDataGroupCount += 1;
            } else if (
                this.selectedLanguage.length !== 0 &&
                this.isDataGroupSelected
            ) {
                this.selectedDataGroupCount += this.selectedLanguage.length;
            }
            this.dataPointName = "";
            this.dataPointId = 0;
            this.selectedLanguage = [];
            this.dataPointArray = [];
            this.isDataTypeSelect = false;
        },

        // remove single data type
        removeSingleDataTypeName(array) {
            this.showSelectedDataPoint = this.showSelectedDataPoint.filter(
                (item) => item !== array
            );
            this.selectedDataPointCount -= 1;

            if (this.showSelectedDataPoint.length === 0) {
                this.selectedDataPointCount = 0;
            }
            if (
                this.showSelectedDataPoint.length === 0 &&
                this.showSelectedDataGroup.length === 0
            ) {
                this.isSelectedTypeSection = false;
                this.showSelectedDataType = [];
            }
        },

        // remove single data group
        removeSingleDataGroupName(array) {
            this.showSelectedDataGroup = this.showSelectedDataGroup.filter(
                (item) => item !== array
            );
            this.selectedDataGroupCount -= 1;

            if (this.showSelectedDataGroup.length === 0) {
                this.selectedDataGroupCount = 0;
            }

            if (
                this.showSelectedDataPoint.length === 0 &&
                this.showSelectedDataGroup.length === 0
            ) {
                this.isSelectedTypeSection = false;
                this.showSelectedDataType = [];
            }
        },

        // remove single data type with name only
        removeSingleDataTypeNameWithLanguage(name, language) {
            this.showSelectedDataPoint.forEach((item) => {
                if (item.name == name) {
                    item.langcode.forEach((res, index) => {
                        if (res === language) {
                            this.multiLanguage.forEach((result) => {
                                if (result.text == language.text) {
                                    result.isSelected = false;
                                }
                            });
                            if (item.langcode.length === 1) {
                                this.showSelectedDataPoint =
                                    this.showSelectedDataPoint.filter(
                                        (element) => element !== item
                                    );
                                this.selectedDataPointCount -= 1;
                            } else {
                                this.selectedDataPointCount -= 1;
                                item.langcode.splice(index, 1);
                            }
                        }
                    });
                }
            });
        },

        // create separate data group and data point array
        createSelectedDataGroup(array) {
            this.showSelectedDataGroup.push(array);
        },
        createSelectedDataPoint(array) {
            this.showSelectedDataPoint.push(array);
        },

        // create array for data group which will pass In API
        createDatapoints() {
            let id = this.dataPointArrayGroup.map((item) => item.id);

            let name = this.dataPointArrayGroup.map((item) => item.name);

            for (let i = 0; i < name.length; i++) {
                this.dataPointArray.push({
                    id: id[i],
                    entityTypeId: this.entityTypeId,
                    name: name[i],
                    langcode: [],
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
.create-dataSet {
    padding: 16px 16px 0 16px;
}

.create-dataSet-router {
    margin-left: $mds-space-half-x;
}

.create-dataSet-heading {
    margin-top: $mds-space-2-x;
    margin-bottom: $mds-space-2-x;
    width: 177px;
    height: 36px;
    left: 16px;
    top: 50px;
    font-style: normal;
    font-weight: 250;
    font-size: 32px;
    line-height: 36px;
    color: #1e1e1e;
}

.create-dataSet-selection-section {
    margin-bottom: 18px;
}

.create-dataSet-inputfield {
    padding: 0px;
    width: 376px;
}

.create-dataSet-border {
    border-bottom: 2px solid #333333;
    height: 0px;
}

.data-point-section {
    height: 343px;
}
.data-set-footer-section {
    height: 61px;
    margin-top: 16px;
}
.data-set-vertical-line {
    border-right: 1px solid #cccccc;
    padding-top: 0px;
    height: 335px;
}
.data-set-heading {
    padding-left: 0px;
    padding-top: $mds-space-quarter-x;
    //   margin-bottom: 13px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #1e1e1e;
    //   height: 37px;
}

.data-point-radio-button {
    margin-top: 13px;
}
.data-point-entity-level {
    margin-left: 30px;
    margin-bottom: $mds-space-1-x;
}

.data-point-list-section {
    margin-left: $mds-space-2-x;
    margin-right: $mds-space-2-x;
}

.data-point-list-group {
    overflow-y: auto;
    height: 270px;
    margin-top: $mds-space-3-x;
    margin-bottom: $mds-space-2-and-a-half-x;
}

.data-point-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
}
.data-point-included-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 4px;
}

.data-point-section-information {
    margin-left: $mds-space-4-x;
    margin-right: 16px;
}

.data-point-discription {
    margin-top: $mds-space-1-x;
    height: 105px;
}

.data-set-selection-of-value-language {
    height: 150px;
}
.data-set-selection-of-value-language-checkbox {
    // border: solid 1px #cccccc;
    // width: 250px;
    // overflow-y: auto;
    height: 150px;
}
.selected-datapoint-language-using-checkbox {
    height: 140px;
    overflow-y: auto;
    width: 100%;
}
.data-set-selection-of-value-language-Nocheckbox {
    // border: 0px;
    // width: 250px;
    overflow-y: auto;
    height: 150px;
}

.addToList-button {
    margin-top: $mds-space-2-x;
    margin-left: 8px;
}

.data-set-selected-data-type {
    margin-left: 0px;
    width: 100%;
    height: fit-content;
    margin-top: 20px;
    font-size: 16px;
}

.data-set-selected-data-type-heading {
    padding-left: 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
}

.data-set-selected-data-type-name {
    margin-left: 0px;
    width: 100%;
}
.dynamic-remove-selected-value {
    float: right;
    margin-top: $mds-space-half-x;
}

.data-Set-list-item-border {
    margin: 0px;
}

.data-set-selected-data-type-list {
    max-height: 300px;
    overflow-y: auto;
}
.data-set-selction-heading-margin {
    margin-left: $mds-space-half-x;
}

.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}

#createDataSet::v-deep
    .mds-layout-grid___VueMDS3Demo
    .mds-layout-grid__col___VueMDS3Demo {
    padding: 0px;
}

#createDataSet::v-deep .mds-list-group__link___VueMDS3Demo {
    padding-left: 0px;
    cursor: context-menu;
}
</style>
